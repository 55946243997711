
import { computed, defineComponent, onMounted, ref } from 'vue';
import store from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: (props, context) => {
    const categories = computed(() => store.category.state.index);
    const $router = useRouter();
    const search = ref('');

    onMounted(async () => {
      await store.category.index();
    });

    const actioned = () => {
      context.emit('action');
    };

    const searchClicked = (event: KeyboardEvent) => {
      if (event.isComposing && event.keyCode === 229) {
        return;
      }

      $router.push({ path: '/', query: { search: search.value } });
      actioned();
      return;
    };

    return {
      categories,
      searchClicked,
      search,
      actioned,
    };
  },
});
