<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import store from '@/store';

export default defineComponent({
  setup: () => {
    const categories = computed(() => store.category.state.index);

    onMounted(async () => {
      await store.category.index();
    });

    return {
      categories,
    };
  },
});
</script>

<template>
  <nav :class="$style.nav">
    <ul :class="$style.list">
      <router-link
        tag="li"
        :class="$style.li"
        :to="`/category/${category.slug}`"
        v-for="(category, i) in categories"
        :key="i"
        >{{ category.name }}</router-link
      >
    </ul>
  </nav>
</template>

<style lang="scss" module>
.nav {
  background-color: $color-light;

  .list {
    margin: 0;
    padding: 12px 0;
    box-sizing: border-box;
    list-style: none;
    display: flex;
    justify-content: center;
    font-size: 13px;

    .li {
      cursor: pointer;
      padding: 0 16px;
      box-sizing: border-box;
      color: $color-text-dark;

      &:hover {
        border-bottom: 4px solid #feceff;
      }
    }
  }
}
</style>
