export interface IMail {
  message: string;
}

export class Mail implements IMail {
  public message = '';

  public constructor(init?: Partial<IMail>) {
    Object.assign(this, init);
  }
}

export interface IMessageSendRequest {
  name: string;
  address: string;
  comment: string;
}
