import { reactive } from 'vue';
import { ITerm, Term } from '@/models/term';
import Axios from 'axios';
import { Error } from '@/models/error';
import { Request } from '@/models/request';
import { IState } from '@/store';

interface ITagState extends IState {
  index: ITerm[];
  popular: ITerm[];
  get: ITerm;
}

export default function tagStore() {
  const state: ITagState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    index: [],
    popular: [],
    get: new Term(),
  });

  const index = async () => {
    return await Request.call(async () => {
      const response = await Axios.get<ITerm[]>(`/tag`);
      state.index = response.data;

      return response.data;
    }, state);
  };

  const get = async (slug: string) => {
    return await Request.call(async () => {
      const response = await Axios.get<ITerm>(`/tag/${slug}`);
      state.get = response.data;

      return response.data;
    }, state);
  };

  const popular = async () => {
    return await Request.call(async () => {
      const response = await Axios.get<ITerm[]>(`/tag/popular`);
      state.popular = response.data;

      return response.data;
    }, state);
  };

  return {
    state,
    index,
    get,
    popular,
  };
}

export type TagStore = ReturnType<typeof tagStore>;
