import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import Axios from 'axios';

Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);
app.use(router);
app.mount('#app');
