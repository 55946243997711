export interface IUser {
  ID: number;
  user_login: string;
  user_nicename: string;
  user_email: string;
  user_url: string;
  display_name: string;
  description: string;
  avatar: string;
}

export class User implements IUser {
  public ID = 0;
  public user_login = '';
  public user_nicename = '';
  public user_email = '';
  public user_url = '';
  public display_name = '';
  public description = '';
  public avatar = '';

  public constructor(init?: Partial<IUser>) {
    Object.assign(this, init);
  }
}
