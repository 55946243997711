import { AxiosResponse } from 'axios';
import { IError, Error } from '@/models/error';
import { IState } from '@/store';

export class Request<T = never> {
  public static async call<T>(callback: () => Promise<T | Error>, state?: IState) {
    if (state) {
      state.notFound = false;
      state.loading = true;
    }

    const response = new Request<T>();
    try {
      response.data = await callback();
    } catch (error) {
      if (error.response) {
        const axiosResponse = error.response as AxiosResponse<IError>;
        response.data = new Error(axiosResponse.data);

        if (state) {
          state.notFound = true;
          state.error = response.data;
        }
      }
    } finally {
      if (state) {
        state.loading = false;
      }
    }

    return response;
  }

  private _data: T | Error = new Error();

  public get data() {
    return this._data;
  }

  public set data(value: T | Error) {
    this._data = value;
  }
}
