
import { computed, defineComponent, onMounted } from 'vue';
import store from '@/store';

export default defineComponent({
  setup: () => {
    const categories = computed(() => store.category.state.index);

    onMounted(async () => {
      await store.category.index();
    });

    return {
      categories,
    };
  },
});
