import { IPostmeta } from './postmeta';
import { ITerm } from './term';

export interface IPost {
  ID?: number;
  post_author?: number;
  post_date?: string;
  post_content?: string;
  post_title?: string;
  post_excerpt?: string;
  post_status?: 'publish' | 'future' | 'draft' | 'pending' | 'private';
  comment_status?: 'open' | 'closed';
  ping_status?: 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'trash' | 'draft' | 'inherit';
  post_name?: string;
  post_modified?: string;
  post_parent?: number;
  menu_order?: number;
  post_type?: 'post';
  comment_count?: number;
  meta?: IPostmeta[];
  tags?: ITerm[];
  categories?: ITerm[];
  guid?: string;
  thumbnail?: string;
  clicked_count?: number;
}

export class Post implements IPost {
  public ID = 0;
  public post_author = 0;
  public post_date = '';
  public post_content = '';
  public post_title = '';
  public post_excerpt = '';
  public post_status?: 'publish' | 'future' | 'draft' | 'pending' | 'private' = 'private';
  public comment_status?: 'open' | 'closed' = 'closed';
  public ping_status?: 'publish' | 'future' | 'draft' | 'pending' | 'private' | 'trash' | 'draft' | 'inherit' =
    'private';
  public post_name = '';
  public post_modified = '';
  public post_parent = 0;
  public menu_order = 0;
  public post_type: 'post' = 'post';
  public comment_count = 0;
  public meta: IPostmeta[] = [];
  public tags: ITerm[] = [];
  public categories: ITerm[] = [];
  public guid = '';
  public thumbnail = '';
  public clicked_count = 0;

  public constructor(init?: Partial<IPost>) {
    Object.assign(this, init);
  }
}
