
import { IPost } from '@/models/post';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object as () => IPost,
      required: true,
    },
    rank: {
      type: Number,
      default: 0,
    },
  },
  setup: () => {
    const replace = (e: Event) => {
      const $img = e.target as HTMLImageElement;
      $img.src = require('@/assets/img/night-sorbet-min.png');
    };

    return {
      replace,
    };
  },
});
