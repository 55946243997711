<template>
  <div :class="$style.component">
    <slot />
  </div>
</template>

<style lang="scss" module>
.component {
  display: flex;
  width: 100% !important;
  max-width: map-get($breakpoints, 'xl') !important;
}

@include mq(lg) {
  .component {
    flex-direction: column;
  }
}
</style>
