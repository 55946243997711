<script lang="ts">
import Spinner from '@/components/atoms/Spinner.vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import store from '@/store';
import Figure from '@/components/molecules/Figure.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Spinner,
    Figure,
  },
  props: {
    categoryIds: {
      type: String,
      required: true,
    },
  },
  setup: props => {
    const ranks = computed(() => store.rank.state.index);
    const rankLoading = ref(false);
    const reccomends = computed(() => store.reccomend.state.index);
    const reccomendLoading = ref(false);
    const popularKeywords = computed(() => store.tag.state.popular);
    const popularKeywordLoading = ref(false);
    const search = ref('');
    const $router = useRouter();

    onMounted(async () => {
      reccomendLoading.value = true;
      popularKeywordLoading.value = true;
      await Promise.all([store.reccomend.index(), store.tag.popular()]);
      reccomendLoading.value = false;
      popularKeywordLoading.value = false;
    });

    watch(props, async () => {
      if (props.categoryIds) {
        const categoryIds = JSON.parse(props.categoryIds) as number[];
        rankLoading.value = true;
        await store.rank.index(categoryIds, 10);
        rankLoading.value = false;
      }
    });

    const searchClicked = (event: KeyboardEvent) => {
      if (event.isComposing && event.keyCode === 229) {
        return;
      }

      $router.push({ path: '/', query: { search: search.value } });
      return;
    };

    return {
      ranks,
      rankLoading,
      reccomends,
      reccomendLoading,
      popularKeywords,
      popularKeywordLoading,
      search,
      searchClicked,
    };
  },
});
</script>

<template>
  <div :class="$style.sidebar">
    <section :class="$style.section">
      <h2 :class="[$style.h2, $style.title]">人気ランキング</h2>
      <Spinner v-if="rankLoading" />
      <div v-else-if="!rankLoading && ranks.length">
        <ul :class="$style.ul">
          <li :class="$style.li" v-for="(rank, i) in ranks" :key="i">
            <router-link :to="`/${rank.post_name}`">
              <Figure :post="rank" :rank="i + 1" />
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section v-if="popularKeywords.length" :class="$style.section">
      <h2 :class="[$style.h2, $style.title]">人気キーワード</h2>
      <Spinner v-if="popularKeywordLoading" />
      <div v-else-if="!popularKeywordLoading && popularKeywords.length">
        <ul :class="$style.popular_keywords">
          <li
            v-for="(keyword, i) in popularKeywords"
            :key="i"
            :class="$style.keyword"
            :style="{ '--font-size': `${keyword.size}px` }"
          >
            <router-link :to="`/tag/${keyword.slug}`">
              {{ keyword.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <section v-if="reccomends.length" :class="$style.section">
      <h2 :class="[$style.h2, $style.title]">おすすめサイト</h2>
      <Spinner v-if="reccomendLoading" />
      <div v-else-if="!reccomendLoading && reccomends.length">
        <ul :class="$style.ul">
          <li v-for="(reccomend, i) in reccomends" :key="i">
            <a :href="reccomend.url" target="_blank">{{ reccomend.title }}</a>
          </li>
        </ul>
      </div>
    </section>
    <section :class="$style.section">
      <h2 :class="[$style.h2, $style.title]">検索</h2>
      <form :class="$style.search" @submit.prevent @keydown.enter.prevent="searchClicked" action="/" method="GET">
        <input type="text" name="search" :class="$style.word" v-model="search" />
        <button :class="$style.search_button" @click="searchClicked">
          <img :class="$style.img" src="@/assets/img/search.svg" alt="icon" />
        </button>
      </form>
    </section>
  </div>
</template>

<style lang="scss" module>
.sidebar {
  width: 100%;

  h1 {
    &.title {
      line-height: 1.3;
      letter-spacing: 0.02em;
      font-size: 40px;
      font-weight: bold;
      border-bottom: 2px solid $color-primary;
    }
  }

  h2 {
    &.title {
      border-bottom: solid 1px #aaa;
    }
  }

  .title {
    font-size: 24px;
    padding: 8px 0;
    font-weight: bold;
    margin: 0 0 10px 0;
  }

  .section {
    background-color: $color-light;
    padding: 16px;
    box-sizing: border-box;

    & + .section {
      margin-top: 16px;
    }
  }

  .ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-weight: bold;

    .li {
      margin: 16px 0;
      padding: 0;
      box-sizing: border-box;
      list-style: none;
    }
  }

  .popular_keywords {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 900;

    .keyword {
      display: inline;
      margin: 0 4px;
      padding: 0;
      font-size: var(--font-size);
      line-height: 1;
    }
  }

  .search {
    display: flex;
    border: solid 2px #ccc;
    border-radius: 5px;
    justify-content: center;
    align-items: center;

    .word {
      flex: 1;
      outline: none;
      box-sizing: border-box;
      height: 30px;
      margin: 0 0;
      padding: 8px;
      border: none;
    }

    .search_button {
      border: none;
      outline: none;
      background-color: #ccc;
      cursor: pointer;

      .img {
        display: block;
        margin: auto;
        padding: 4px;
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
}

@include mq(lg) {
  .component {
    h1 {
      &.title {
        font-size: 25px;
        font-weight: bold;
        margin: 15px 0;
      }
    }
  }
}
</style>
