import { reactive } from 'vue';
import { IOption, Option } from '@/models/option';
import Axios from 'axios';
import { Error } from '@/models/error';
import { Request } from '@/models/request';
import { IState } from '@/store';

interface IOptionState extends IState {
  get: IOption;
}

export default function optionStore() {
  const state: IOptionState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    get: new Option(),
  });

  const title = async () => {
    return await Request.call(async () => {
      const response = await Axios.get<IOption>(`/option/title`);
      state.get = response.data;

      return response.data;
    }, state);
  };

  return {
    state,
    title,
  };
}

export type OptionStore = ReturnType<typeof optionStore>;
