import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/pages/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/pages/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/components/pages/Logout.vue'),
  },
  {
    path: '/preview',
    name: 'Post',
    component: () => import('@/components/pages/Post.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/components/pages/Contact.vue'),
  },
  {
    path: '/author/:slug',
    name: 'Author',
    component: () => import('@/components/pages/Author.vue'),
  },
  {
    path: '/author/:slug/page/:number',
    name: 'AuthorPage',
    component: () => import('@/components/pages/Author.vue'),
  },
  {
    path: '/category/:slug',
    name: 'Category',
    component: () => import('@/components/pages/Category.vue'),
  },
  {
    path: '/category/:slug/page/:number',
    name: 'CategoryPage',
    component: () => import('@/components/pages/Category.vue'),
  },
  {
    path: '/tag/:slug',
    name: 'Tag',
    component: () => import('@/components/pages/Tag.vue'),
  },
  {
    path: '/tag/:slug/page/:number',
    name: 'TagPage',
    component: () => import('@/components/pages/Tag.vue'),
  },
  {
    path: '/page/:number',
    name: 'Page',
    component: () => import('@/components/pages/Home.vue'),
  },
  {
    path: '/:slug',
    name: 'Post',
    component: () => import('@/components/pages/Post.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/components/pages/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: to => {
    if (!to.hash) {
      return { left: 0, top: 0 };
    }
  },
});

export default router;
