export interface IPaginate<T> {
  total: number;
  page: number;
  per_page: number;
  data: T[];
}

export class Paginate<T> implements IPaginate<T> {
  public total = 0;
  public page = 0;
  public per_page = 0;
  public data: T[] = [];

  public constructor(init?: Partial<IPaginate<T>>) {
    Object.assign(this, init);
  }
}
