
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, context) => {
    const hamburgerClicked = () => {
      context.emit('hamburger');
    };

    return {
      hamburgerClicked,
    };
  },
});
