import { reactive } from 'vue';
import { IPost, Post } from '@/models/post';
import Axios from 'axios';
import { Error } from '@/models/error';
import { Request } from '@/models/request';
import { IState } from '@/store';
import { IPaginate, Paginate } from '@/models/paginate';

interface IPostState extends IState {
  index: IPaginate<IPost>;
  get: IPost;
}

interface IPostRequestOption {
  page: number;
  per_page: number;
  term?: string;
  search?: string;
}

export default function postStore() {
  const state: IPostState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    index: new Paginate(),
    get: new Post(),
  });

  const get = async (slug: string) => {
    return await Request.call(async () => {
      const response = await Axios.get<IPost>(`/post/${slug}`);
      state.get = response.data;

      return response.data;
    }, state);
  };

  const preview = async (id: number) => {
    return await Request.call(async () => {
      const response = await Axios.get<IPost>(`/post/${id}/preview`, {
        params: {
          preview: true,
        },
      });
      state.get = response.data;

      return response.data;
    }, state);
  };

  const index = async (option: IPostRequestOption) => {
    option.page = option.page || 1;
    option.per_page = option.per_page || 12;
    return await Request.call(async () => {
      const response = await Axios.get<IPaginate<IPost>>('/post', {
        params: option,
      });
      state.index = response.data;

      return response.data;
    }, state);
  };

  const clickCounter = async (slug: string) => {
    return await Request.call(async () => {
      const response = await Axios.get(`/post/${slug}/clicked`);

      return response.data;
    });
  };

  return {
    state,
    get,
    preview,
    index,
    clickCounter,
  };
}

export type PostStore = ReturnType<typeof postStore>;
