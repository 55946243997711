<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import store from '@/store';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup: (props, context) => {
    const categories = computed(() => store.category.state.index);
    const $router = useRouter();
    const search = ref('');

    onMounted(async () => {
      await store.category.index();
    });

    const actioned = () => {
      context.emit('action');
    };

    const searchClicked = (event: KeyboardEvent) => {
      if (event.isComposing && event.keyCode === 229) {
        return;
      }

      $router.push({ path: '/', query: { search: search.value } });
      actioned();
      return;
    };

    return {
      categories,
      searchClicked,
      search,
      actioned,
    };
  },
});
</script>

<template>
  <div :class="$style.menu">
    <form :class="$style.search" @submit.prevent action="/" method="GET">
      <input type="text" name="search" :class="$style.word" v-model="search" />
      <button :class="$style.search_button" @click="searchClicked">
        <img :class="$style.img" src="@/assets/img/search.svg" alt="icon" />
      </button>
    </form>

    <ul :class="$style.categories">
      <li :class="$style.parent" v-for="(category, i) in categories" :key="i" @click="actioned">
        <router-link :to="`/category/${category.slug}`">{{ category.name }}</router-link>
        <ul :class="$style.children">
          <li :class="$style.child" v-for="(child, i) in category.children" :key="i">
            <router-link :to="`/category/${child.slug}`">{{ child.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>

    <ul :class="$style.sns">
      <li :class="$style.twitter" @click="actioned">
        <a target="_blank" href="https://twitter.com/NightSorbet">
          <img :class="$style.img" src="@/assets/img/twitter-white.svg" alt="twitter" />
        </a>
      </li>
      <li :class="$style.youtube" @click="actioned">
        <a target="_blank" href="https://www.youtube.com/channel/UCXoqr0QSllE00NtXlvGVUdQ/featured">
          <img :class="$style.img" src="@/assets/img/youtube-white.svg" alt="youtube" />
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" module>
.menu {
  background-color: #ffefff;
  width: 280px;
  height: 100%;
  box-shadow: -5px 0 4px 2px rgba(0, 0, 0, 0.2);
  font-family: 'M PLUS 1p', sans-serif;
  padding: 65px 16px;
  box-sizing: border-box;

  .search {
    display: flex;
    border: solid 2px #ccc;
    border-radius: 5px;
    justify-content: center;
    align-items: center;

    .word {
      flex: 1;
      outline: none;
      box-sizing: border-box;
      height: 30px;
      margin: 0 0;
      padding: 8px;
      border: none;
    }

    .search_button {
      border: none;
      outline: none;
      background-color: #ccc;
      cursor: pointer;

      .img {
        display: block;
        margin: auto;
        padding: 4px;
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
  }

  .categories {
    margin: 16px 0;
    padding: 0;
    list-style: none;

    .parent {
      font-size: 18px;

      a {
        color: #444;
      }

      .children {
        list-style: none;

        .child {
          font-size: 13px;
          color: #666;
          margin: 8px 0;
        }
      }
    }
  }

  .sns {
    list-style: none;
    margin: 32px 0;
    padding: 0;
    display: flex;

    & > li {
      background-color: #444;
      border-radius: 50%;
      margin: 8px;

      .img {
        display: block;
        width: 30px;
        height: 30px;
        padding: 8px;
        cursor: pointer;
      }
    }
  }
}
</style>
