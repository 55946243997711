import { reactive } from 'vue';
import { IComment, ICommentPostRequest } from '@/models/comment';
import Axios from 'axios';
import { Error } from '@/models/error';
import { Request } from '@/models/request';
import { IState } from '@/store';

interface ICommentState extends IState {
  index: IComment[];
}

export default function commentStore() {
  const state: ICommentState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    index: [],
  });

  const index = async (slug: string) => {
    return await Request.call(async () => {
      const response = await Axios.get<IComment[]>(`/post/${slug}/comment`);
      state.index = response.data;

      return response.data;
    }, state);
  };

  const post = async (slug: string, request: ICommentPostRequest) => {
    return await Request.call(async () => {
      const response = await Axios.post(`/post/${slug}/comment`, request);
      return response.data;
    }, state);
  };

  return {
    state,
    index,
    post,
  };
}

export type CommentStore = ReturnType<typeof commentStore>;
