import { reactive } from 'vue';
import { IUser, User } from '@/models/user';
import Axios from 'axios';
import { Error } from '@/models/error';
import { Request } from '@/models/request';
import { IState } from '@/store';

interface IUserState extends IState {
  get: IUser;
}

export default function userStore() {
  const state: IUserState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    get: new User(),
  });

  const get = async (id: number) => {
    return await Request.call(async () => {
      const response = await Axios.get<IUser>(`/user/${id}`);
      state.get = response.data;

      return response.data;
    }, state);
  };

  const name = async (name: string) => {
    return await Request.call(async () => {
      const response = await Axios.get<IUser>(`/user/${name}`);
      state.get = response.data;

      return response.data;
    }, state);
  };

  return {
    state,
    get,
    name,
  };
}

export type UserStore = ReturnType<typeof userStore>;
