export interface ITerm {
  object_id?: number;
  term_taxonomy_id?: number;
  term_order?: number;
  term_id?: number;
  taxonomy?: string;
  description?: string;
  parent?: number;
  count?: number;
  name?: string;
  term_group?: number;
  children?: {
    object_id?: number;
    term_taxonomy_id?: number;
    term_order?: number;
    term_id?: number;
    taxonomy?: string;
    description?: string;
    parent?: number;
    count?: number;
    name?: string;
    term_group?: number;
  }[];
  parent_object?: {
    object_id?: number;
    term_taxonomy_id?: number;
    term_order?: number;
    term_id?: number;
    taxonomy?: string;
    description?: string;
    parent?: number;
    count?: number;
    name?: string;
    term_group?: number;
  };
}

export class Term implements ITerm {
  public object_id = 0;
  public term_taxonomy_id = 0;
  public term_order = 0;
  public term_id = 0;
  public taxonomy = '';
  public description = '';
  public parent = 0;
  public count = 0;
  public name = '';
  public term_group = 0;

  public constructor(init?: Partial<ITerm>) {
    Object.assign(this, init);
  }
}
