interface Font {
  name: string;
  styles?: number[];
}

export default (fonts: Font[]) => {
  const f = fonts
    .map(font => {
      const fontName = 'family=' + font.name.split(' ').join('+');
      const weight = font.styles?.length ? ':wght@' : '';
      const styles = font.styles?.join(';') || '';

      return `${fontName}${weight}${styles}`;
    })
    .join('&');

  return `https://fonts.googleapis.com/css2?${f}&display=swap`;
};
