<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import store from '@/store';

export default defineComponent({
  props: {
    isScrolled: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    const title = computed(() => store.option.state.get.option_value);

    onMounted(async () => {
      await store.option.title();
    });

    return {
      title,
    };
  },
});
</script>

<template>
  <header :class="$style.header">
    <router-link :class="{ [$style.title]: true, [$style.is_scrolled]: isScrolled }" tag="h1" to="/">
      <!-- <h1 :class="{ [$style.title]: true, [$style.is_scrolled]: isScrolled }"> -->
      <span>Night Sorbet</span>
      <span v-if="!isScrolled" :class="$style.sub_title">{{ title }}</span>
      <!-- </h1> -->
    </router-link>
  </header>
</template>

<style lang="scss" module>
.header {
  background: $color-black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;

  .title {
    color: $color-primary;
    width: fit-content;
    font-size: 32px;
    padding: 16px;
    box-sizing: border-box;
    margin: auto;
    font-family: 'Pacifico', cursive;
    text-align: center;
    display: flex;
    flex-direction: column;
    transition: padding ease 0.1s;

    &.is_scrolled {
      color: #ffd6ff;
      font-size: 24px;
      font-family: 'Pacifico', cursive;
      text-align: left;
      padding: 4px 16px;
      margin: 0;
    }

    @include mq(lg) {
      color: #ffd6ff;
      font-size: 24px;
      font-family: 'Pacifico', cursive;
      text-align: left;
      padding: 4px 16px;
      align-items: center;
      flex-direction: row;
      margin: 0;

      & > span:not(:first-child) {
        margin-left: 16px;

        @include mq(md) {
          display: none;
        }
      }
    }

    .sub_title {
      margin: 8px;
      font-size: 14px;
      font-family: 'M PLUS 1p', sans-serif;
    }
  }
}
</style>
