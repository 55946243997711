import { reactive } from 'vue';
import { IMail, IMessageSendRequest, Mail } from '@/models/mail';
import Axios from 'axios';
import { Error } from '@/models/error';
import { Request } from '@/models/request';
import { IState } from '@/store';

interface IMailState extends IState {
  send: IMail;
}

export default function mailStore() {
  const state: IMailState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    send: new Mail(),
  });

  const send = async (message: IMessageSendRequest) => {
    return await Request.call(async () => {
      const response = await Axios.post<IMail>(`/mail`, message);
      state.send = response.data;

      return response.data;
    }, state);
  };

  return {
    state,
    send,
  };
}

export type MailStore = ReturnType<typeof mailStore>;
