<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  setup: (props, context) => {
    const hamburgerClicked = () => {
      context.emit('hamburger');
    };

    return {
      hamburgerClicked,
    };
  },
});
</script>

<template>
  <div :class="{ [$style.hamburger]: true, [$style.is_active]: isOpened }" @click="hamburgerClicked">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<style lang="scss" module>
.hamburger {
  position: relative;
  display: none;
  box-sizing: border-box;
  width: 32px;
  height: 16px;

  @include mq(lg) {
    display: inline-block;
  }

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: $color-primary;
    transition: all 0.4s;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }

  &.is_active {
    span {
      &:nth-of-type(1) {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        background-color: #000;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        background-color: #000;
      }
    }
  }
}
</style>
