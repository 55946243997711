export interface IOption {
  option_id?: number;
  option_name?: string;
  option_value?: string;
}

export class Option implements IOption {
  public option_id = 0;
  public option_name = '';
  public option_value = '';

  public constructor(init?: Partial<IOption>) {
    Object.assign(this, init);
  }
}
