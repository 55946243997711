<script lang="ts">
import Header from '@/components/organisms/Header.vue';
import Nav from '@/components/organisms/Nav.vue';
import Menu from '@/components/organisms/Menu.vue';
import Footer from '@/components/organisms/Footer.vue';
import Sidebar from '@/components/organisms/Sidebar.vue';
import Container from '@/components/atoms/Container.vue';
import { onMounted, ref } from 'vue';
import store from '@/store';
import { useRoute } from 'vue-router';
import GoogleFonts from '@/plugins/google_fonts';
import Hamburger from '@/components/atoms/Hamburger.vue';

export default {
  components: {
    Header,
    Nav,
    Footer,
    Sidebar,
    Container,
    Menu,
    Hamburger,
  },
  setup: () => {
    const categoryIds = ref('');
    const isScrolled = ref(false);
    const isOpened = ref(false);
    const route = useRoute();

    onMounted(async () => {
      const $link = document.createElement('link');
      $link.setAttribute('rel', 'stylesheet');
      $link.setAttribute('href', GoogleFonts([{ name: 'Pacifico', styles: [400] }]));
      const head = document.querySelector('head');
      head?.appendChild($link);

      window.addEventListener('scroll', () => {
        if (window.pageYOffset === 0) {
          isScrolled.value = false;
        } else {
          isScrolled.value = true;
        }
      });
    });

    const loadedContent = () => {
      const _categoryIds = store.post.state.get.categories?.map(category => category.term_id as number) || [];
      categoryIds.value = JSON.stringify(_categoryIds);
    };

    const hamburgerClicked = () => {
      isOpened.value = !isOpened.value;
    };

    return {
      loadedContent,
      categoryIds,
      route,
      isScrolled,
      isOpened,
      hamburgerClicked,
    };
  },
};
</script>

<template>
  <div :class="$style.component">
    <div :class="$style.header">
      <Header :isScrolled="isScrolled" @hamburger="hamburgerClicked"> </Header>
      <Nav :class="$style.nav" v-if="!isScrolled" />
      <Menu :class="{ [$style.menu]: true, [$style.is_opened]: isOpened }" @action="hamburgerClicked" />
      <Hamburger :isOpened="isOpened" :class="$style.hamburger" @hamburger="hamburgerClicked" />
    </div>
    <Container :class="{ [$style.container]: true, [$style.is_scrolled]: isScrolled }">
      <div :class="$style.main">
        <router-view @loaded="loadedContent" :key="`${route.path}${route.query.search || ''}`" />
      </div>
      <div :class="$style.sidebar">
        <Sidebar :categoryIds="categoryIds" />
      </div>
    </Container>
    <Footer />
  </div>
</template>

<style lang="scss" src="@/assets/scss/app.scss"></style>
<style lang="scss" src="@/assets/scss/origin.scss"></style>
<style lang="scss" module>
.component {
  width: 100%;
  height: 100%;
  position: relative;

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .nav {
    display: block;

    @include mq(lg) {
      display: none;
    }
  }

  .hamburger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    display: none;
    cursor: pointer;

    @include mq(lg) {
      display: inline-block;
    }
  }

  .menu {
    opacity: 0;
    position: fixed;
    top: 0;
    right: -100%;
    transition: opacity ease-in-out 0.3s, right ease-out 0.3s;

    &.is_opened {
      opacity: 1;
      right: 0;
    }
  }

  .container {
    margin: 0 auto;
    padding: 180px 0 0 0;
    max-width: map-get($breakpoints, 'xl');

    &.is_scrolled {
      padding: 50px 0 0 0;
    }

    @include mq(lg) {
      padding: 50px 0 0 0;
    }
  }

  .main {
    flex: 1;
    margin: 16px;
    height: fit-content;
  }

  .sidebar {
    margin: 16px 16px 16px 0;
    width: 300px;
    box-sizing: border-box;
  }
}

@include mq(lg) {
  .component {
    .main {
      max-width: none;
      padding: 8px;
      margin: 0;
    }

    .sidebar {
      padding: 8px;
      margin: 0;
      width: 100%;
    }
  }
}
</style>
