
import Header from '@/components/organisms/Header.vue';
import Nav from '@/components/organisms/Nav.vue';
import Menu from '@/components/organisms/Menu.vue';
import Footer from '@/components/organisms/Footer.vue';
import Sidebar from '@/components/organisms/Sidebar.vue';
import Container from '@/components/atoms/Container.vue';
import { onMounted, ref } from 'vue';
import store from '@/store';
import { useRoute } from 'vue-router';
import GoogleFonts from '@/plugins/google_fonts';
import Hamburger from '@/components/atoms/Hamburger.vue';

export default {
  components: {
    Header,
    Nav,
    Footer,
    Sidebar,
    Container,
    Menu,
    Hamburger,
  },
  setup: () => {
    const categoryIds = ref('');
    const isScrolled = ref(false);
    const isOpened = ref(false);
    const route = useRoute();

    onMounted(async () => {
      const $link = document.createElement('link');
      $link.setAttribute('rel', 'stylesheet');
      $link.setAttribute('href', GoogleFonts([{ name: 'Pacifico', styles: [400] }]));
      const head = document.querySelector('head');
      head?.appendChild($link);

      window.addEventListener('scroll', () => {
        if (window.pageYOffset === 0) {
          isScrolled.value = false;
        } else {
          isScrolled.value = true;
        }
      });
    });

    const loadedContent = () => {
      const _categoryIds = store.post.state.get.categories?.map(category => category.term_id as number) || [];
      categoryIds.value = JSON.stringify(_categoryIds);
    };

    const hamburgerClicked = () => {
      isOpened.value = !isOpened.value;
    };

    return {
      loadedContent,
      categoryIds,
      route,
      isScrolled,
      isOpened,
      hamburgerClicked,
    };
  },
};
