import postStore from '@/stores/post';
import userStore from '@/stores/user';
import optionStore from '@/stores/option';
import rankStore from './stores/rank';
import relationStore from './stores/relation';
import reccomendStore from './stores/reccomend';
import categoryStore from './stores/category';
import tagStore from './stores/tag';
import commentStore from './stores/comment';
import mailStore from './stores/mail';
import authStore from './stores/auth';
import { IError } from './models/error';

export default {
  post: postStore(),
  user: userStore(),
  option: optionStore(),
  rank: rankStore(),
  relation: relationStore(),
  reccomend: reccomendStore(),
  category: categoryStore(),
  tag: tagStore(),
  comment: commentStore(),
  mail: mailStore(),
  auth: authStore(),
};

export interface IState {
  loading: boolean;
  notFound: boolean;
  error: IError;
}
