<script lang="ts">
import { IPost } from '@/models/post';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    post: {
      type: Object as () => IPost,
      required: true,
    },
    rank: {
      type: Number,
      default: 0,
    },
  },
  setup: () => {
    const replace = (e: Event) => {
      const $img = e.target as HTMLImageElement;
      $img.src = require('@/assets/img/night-sorbet-min.png');
    };

    return {
      replace,
    };
  },
});
</script>

<template>
  <div :class="$style.component">
    <div :class="$style.wrapper">
      <img v-if="post.thumbnail" :class="$style.img" :src="post.thumbnail" :alt="`${post.ID}画像`" @error="replace" />
      <img v-else :class="$style.img" src="@/assets/img/night-sorbet-min.png" :alt="`${post.ID}画像`" />

      <p v-if="rank" :class="$style.rank">{{ rank }}</p>
    </div>
    <p :class="$style.title">{{ post.post_title }}</p>
  </div>
</template>

<style lang="scss" module>
.component {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;

  .wrapper {
    width: 80px;
    height: 80px;
    position: relative;

    .img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }

    .rank {
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      background: #ffbdff;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
    }
  }

  .title {
    display: -webkit-box;
    flex: 1;
    margin: 0;
    padding: 0 8px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    color: #3a76d7;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:hover {
    .title {
      text-decoration-line: underline;
    }
  }
}
</style>
