<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import store from '@/store';

export default defineComponent({
  setup: () => {
    const categories = computed(() => store.category.state.index);

    onMounted(async () => {
      await store.category.index();
    });

    return {
      categories,
    };
  },
});
</script>

<template>
  <footer :class="$style.footer">
    <h3 :class="$style.title">menu</h3>
    <ul :class="$style.list">
      <li v-for="(category, i) in categories" :key="i" :class="$style.category">
        <router-link :class="$style.text" :to="`/category/${category.slug}`">{{ category.name }}</router-link>
        <ul :class="$style.children">
          <li v-for="(category, i) in category.children" :key="i" :class="$style.category">
            <router-link :class="$style.text" :to="`/category/${category.slug}`">{{ category.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
    <router-link :class="$style.link" to="/about">ナイトソルベについて</router-link>
    <router-link :class="$style.link" to="/contact">お問い合せ</router-link>
    <ul :class="$style.sns">
      <li :class="$style.twitter">
        <a target="_blank" href="https://twitter.com/NightSorbet">
          <img :class="$style.img" src="@/assets/img/twitter.svg" alt="twitter" />
        </a>
      </li>
      <li :class="$style.youtube">
        <a target="_blank" href="https://www.youtube.com/channel/UCXoqr0QSllE00NtXlvGVUdQ/featured">
          <img :class="$style.img" src="@/assets/img/youtube.svg" alt="youtube" />
        </a>
      </li>
    </ul>
    <p :class="$style.copy">&copy;2015 ナイトソルベ All right reserved.</p>
  </footer>
</template>

<style lang="scss" module>
.footer {
  padding: 16px;
  background-color: $color-dark;
  color: $color-text-light;

  .title {
    font-family: 'Pacifico', cursive;
    margin: 0;
    font-size: 25px;
    color: #fff;
    line-height: 1;
  }

  .list {
    margin: 16px 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-size: 20px;

    .children {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;
      font-size: 14px;
    }
  }

  .category {
    .text {
      margin: 4px;
      padding: 2px 8px;
      box-sizing: border-box;
      width: fit-content;
      background-color: $color-primary;
      color: $color-text-dark;

      &:hover {
        background-color: $color-light;
      }
    }
  }

  .link {
    display: block;
    width: fit-content;
    color: $color-text-light;
  }

  .sns {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    & > li {
      background-color: #ffd6ff;
      border-radius: 50%;
      margin: 8px;

      .img {
        display: block;
        width: 30px;
        height: 30px;
        padding: 8px;
        cursor: pointer;
      }
    }
  }

  .copy {
    font-size: 11px;
    text-align: center;
  }
}
</style>
