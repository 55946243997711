import { IAuth } from '@/models/auth';
import { reactive } from 'vue';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { IState } from '@/store';
import { Request } from '@/models/request';
import { IUser, User } from '@/models/user';

interface IAuthState extends IState {
  me: IUser;
  token: string;
  id: number;
}

export default () => {
  const state: IAuthState = reactive({
    loading: false,
    notFound: false,
    error: new Error(),
    me: new User(),
    token: '',
    id: 0,
  });

  const setBearer = () => {
    Axios.defaults.headers.Authorization = `Bearer ${state.token}`;
  };

  const removeBearer = () => {
    Axios.defaults.headers.Authorization = '';
  };

  const init = async () => {
    state.token = Cookies.get('access_token');
    state.id = Cookies.get('access_id');

    if (!state.token || !state.id) {
      logout();
      return;
    }

    setBearer();

    try {
      const response = await Axios.get<IUser>(`/oauth/${state.id}`);

      if (!response.data) {
        return false;
      }

      state.me = new User(response.data);
      return true;
    } catch {
      return false;
    }
  };

  const login = async (credential: { username: string; password: string }) => {
    return await Request.call(async () => {
      const response = await Axios.get<IAuth>('/oauth/token', {
        params: credential,
      });
      state.id = response.data.data.id || 0;
      state.token = response.data.data.token || '';
      Cookies.set('access_token', state.token);
      Cookies.set('access_id', state.id);
      return response.data;
    }, state);
  };

  const logout = async () => {
    try {
      state.id = 0;
      state.token = '';
      state.me = new User();
      Cookies.remove('access_token');
      Cookies.remove('access_id');
      removeBearer();

      return true;
    } catch {
      return false;
    }
  };

  const me = async (id: number) => {
    return await Request.call(async () => {
      const response = await Axios.get<IUser>(`/oauth/${id}`);
      state.me = response.data;

      return response.data;
    }, state);
  };

  return {
    state,
    init,
    login,
    logout,
    me,
  };
};
