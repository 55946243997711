
import { computed, defineComponent, onMounted } from 'vue';
import store from '@/store';

export default defineComponent({
  props: {
    isScrolled: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    const title = computed(() => store.option.state.get.option_value);

    onMounted(async () => {
      await store.option.title();
    });

    return {
      title,
    };
  },
});
