
import Spinner from '@/components/atoms/Spinner.vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import store from '@/store';
import Figure from '@/components/molecules/Figure.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Spinner,
    Figure,
  },
  props: {
    categoryIds: {
      type: String,
      required: true,
    },
  },
  setup: props => {
    const ranks = computed(() => store.rank.state.index);
    const rankLoading = ref(false);
    const reccomends = computed(() => store.reccomend.state.index);
    const reccomendLoading = ref(false);
    const popularKeywords = computed(() => store.tag.state.popular);
    const popularKeywordLoading = ref(false);
    const search = ref('');
    const $router = useRouter();

    onMounted(async () => {
      reccomendLoading.value = true;
      popularKeywordLoading.value = true;
      await Promise.all([store.reccomend.index(), store.tag.popular()]);
      reccomendLoading.value = false;
      popularKeywordLoading.value = false;
    });

    watch(props, async () => {
      if (props.categoryIds) {
        const categoryIds = JSON.parse(props.categoryIds) as number[];
        rankLoading.value = true;
        await store.rank.index(categoryIds, 10);
        rankLoading.value = false;
      }
    });

    const searchClicked = (event: KeyboardEvent) => {
      if (event.isComposing && event.keyCode === 229) {
        return;
      }

      $router.push({ path: '/', query: { search: search.value } });
      return;
    };

    return {
      ranks,
      rankLoading,
      reccomends,
      reccomendLoading,
      popularKeywords,
      popularKeywordLoading,
      search,
      searchClicked,
    };
  },
});
