export interface IError {
  message: string;
}

export class Error implements IError {
  private _message = '';

  public constructor(response?: IError) {
    if (response) {
      this._message = response.message;
    }
  }

  public get message() {
    return this._message;
  }
}
